<template>

  <div>
    <checker />
    <b-card
      no-body
    >
      <b-card-body class="px-0" v-if="Object.keys(this.stats).length > 0">
        <div class="m-2">

          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="6"
              class="mb-1 mb-md-0"
            >
              <label>Entries per page</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector mx-50"
              />
            </b-col>
            <!-- Search -->
            <b-col
              cols="6"
            >
              <label>Quick Search</label>
              <b-form-input
                v-model="searchQuery"
                class="mr-1"
                placeholder="Search..."
              />
            </b-col>
          </b-row>

        </div>

        <b-table
          class="position-relative"
          :items="Object.values(stats[this.$route.name])"
          responsive
          primary-key="id"
          show-empty
          :current-page="currentPage"
          :total-rows="totalRisks"
          :per-page="perPage"
          :fields="fields[this.$route.name]"
          empty-text="No matching records found"
        >
        <template #cell(risks)="data">
            <div v-for="scenario in data.item.scenarios" :key="scenario.id">
              <img
                width="24"
                :src="require(`@/assets/images/logos/${scenario.app_name}.webp`)"
              > <b-link
                  :to="{ name: 'apps-scenarios-view', params: { id: scenario.id } }"
                  class="font-weight-bold d-inline-block text-nowrap"
                > {{ scenario.error }}
                </b-link>
            </div>
          </template>
          <template #cell(resourceName)="data">
            <img :src="data.item.type">{{" " + data.item.id}}
          </template>
          <template #cell(path)="data">
            <span v-if="data.item.path && data.item.path.startsWith('http')">
              <a target="_blank" :href="data.item.path">link</a>
            </span>
            <span v-else>
              {{data.item.path}}
            </span>
          </template>
          <template #cell(raw)="data">
            <a v-b-modal="`modal-${data.item.id}`">view</a>
            <b-modal
              hide-footer
              hide-header
              size="lg"
              :id="`modal-${data.item.id}`"
              >
              <json-viewer :value="showJsonViewer(data.item.raw)"></json-viewer>
            </b-modal>
          </template>
          <template #cell(fix)="data">
            <router-link
            :to="`/action/${data.item.scenarios[0].fix}_${data.item.scenarios[0].app_name}`"
            v-if="data.item.scenarios[0].fix"
            class="text-success">
              Fix Now
            </router-link>
          </template>
        </b-table>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalRisks"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable,
  BPagination, BCardBody, BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import useScenariosList from './api/useScenariosList'
import scenarioStoreModule from './api/scenarioStoreModule'
import checker from './Checker.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BLink,
    BCardBody,
    BTable,
    BPagination,
    checker,
    vSelect,
  },
  data() {
    return {
      stats: {},
      perPage: 25,
      currentPage: 1,
      fields: {
        configurations: ['id', 'risks', 'raw', 'fix'],
        identities: ['id', 'name', 'risks', 'raw', 'fix'],
        resources: ['resourceName', 'link', 'path', 'risks', 'raw', 'fix'],
      },
    }
  },
  mounted() {
    const stats = localStorage.getItem('stats')
    if (stats) {
      this.stats = JSON.parse(stats)
    }
    this.fetchStats(null, this.initRisks)
  },
  methods: {
    initRisks(stats) {
      this.stats = stats
      this.stats = JSON.parse(localStorage.getItem('stats'))
    },
  },
  computed: {
    totalRisks() {
      return Object.values(this.stats[this.$route.name]).length
    },
  },
  setup() {
    const SCENARIO_APP_STORE_MODULE_NAME = 'app-scenario'

    // Register module
    if (!store.hasModule(SCENARIO_APP_STORE_MODULE_NAME)) store.registerModule(SCENARIO_APP_STORE_MODULE_NAME, scenarioStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SCENARIO_APP_STORE_MODULE_NAME)) store.unregisterModule(SCENARIO_APP_STORE_MODULE_NAME)
    })

    const {
      fetchStats,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
    } = useScenariosList()

    return {
      fetchStats,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
